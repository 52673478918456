.cardContainer {
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    overflow: hidden;
    z-index: -99;
}

.overlayer {
    filter: brightness(10%);
}

@media (min-width : 300px) and (max-width : 700px) {
    .cardContainer{
        width: 100%;
    }
}