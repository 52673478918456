.heroSection-bg {
    background: url(/src/assets/images/Hero\ Image.png);
    background-size: cover;
}


@media (min-width : 300px) and (max-width : 1100px) {
    .heroSection-bg {
        background: url(/src/assets/images/mobile_header2.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 500px;
    }
}