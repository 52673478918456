.footer_contact.contact .heading {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
}

.footer_contact.contact span {
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.85px;
}

.copyright {
    display: flex;
    height: 20px;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    align-items: baseline;
}

.copyright::before {
    content: '';
    background: #7714C2;
    height: 2px;
    width: 93vw;
    display: inline-block;
    position: absolute;
}