.about-us .title {
    color: #303030;
    font-family: 'Inter', sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    align-items: center;
    line-height: normal;
    letter-spacing: 2px;
    display: flex;
}

.title.card-title.h5::after {
    content: '';
    width: 209px;
    display: inline-block;
    height: 2px;
    background-color: #7714C2;
    margin-left: 10px;
}

.about-us .title-text {
    color: #7714C2;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.25px;
}

.about-us .title-desc {
    color: #303030;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
}

.about-us .purple-box {
    margin: 0;
    background: #7714C2;
    border-radius: 12px !important;
}



/* .about-us .main-div .purple-box div {
    width: calc(100% - 427px);
} */

.about-us .purple-box .purple-box-text {
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.9px;
}


@media (min-width : 300px) and (max-width : 800px) {
    .aboutDiv {
        margin: 0rem !important;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    .about-us .title {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    .purple-box .aboutImage {
        position: relative !important;
    }
}

@media (min-width : 600px) {
    .aboutDiv {
        margin: 2rem;
    }
}