.imageCardDiv {
    width: 45%;
    max-width: 550px;
}

.description {
    width: 45%;
}

@media (min-width : 300px) and (max-width : 850px) {
    .serviceCardDiv {
        flex-direction: column;
        width: 100%;
        row-gap: 2rem;
        align-items: center;
        padding-left: 0.5rem;
        padding-right:  0.5rem;
    }

    .imageCardDiv {
        width: 100%;
    }

    .description {
        width: 100%;
        text-align: left;
    }

    .description p{
        font-size: 1rem;
    }
}