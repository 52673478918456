.navbar {
    background-color: transparent;
    height: 70px;
    width: 100vw;
    z-index: 100;

}

.navbar.nocolorChange {
    position: fixed;
    top: 0;
    /* display: flex; */
    /* justify-content: center; */
}

.navbar.colorChange {
    background-color: white;
    height: 90px;
    width: 100vw;
    display: flex;
    z-index: 100;
    position: fixed;
    top: 0;
    color: black;
    border-bottom: 1px solid rgba(128, 128, 128, 0.536);
}

.navlist.nocolorChange {
    color: white;
    font-size: 1.25rem
        /* 20px */
    ;
    line-height: 1.75rem
        /* 28px */
    ;
    font-weight: 500;
    z-index: 1000;
}


.navlist.colorChange {
    color: black;
    font-size: 1.25rem
        /* 20px */
    ;
    line-height: 1.75rem
        /* 28px */
    ;
    font-weight: 500;
    z-index: 1000;


}

.navDiv li:hover .underDiv {
    cursor: pointer;
    background-color: #7714C2;
    width: 100%;
    height: 3px;
}

.activeScroll {}

.activeScroll .underDiv {
    background-color: #7714C2;
    width: 100%;
    height: 3px;
}

@media (min-width : 300px) and (max-width:600px) {
    .navLogo{
        width: 60%;
    }
}