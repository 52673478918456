.service .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.service .heading::before,
.service .heading::after {
    content: '';
    width: 209px;
    height: 2px;
    background-color: #7714C2;
    position: relative;
    display: inline;
    transform: translateY(-200%);
}

.service .title {
    color: #303030;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2px;
}


.service .sub-title {
    display: flex;
    width: 298px;
    height: 70px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #303030;
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.75px;
    margin-left: 2.8rem;
}

.service .sub-title span {
    color: #7714C2;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.75px;
}

.service .sub-title .line1 {
    width: 141px;
    height: 3px;
    position: relative;
    display: inline-block;
    background: rgba(119, 20, 194, 0.50);
    left: 0px;
}

.service .rectangle-box {
    align-items: center;
    flex-shrink: 0;
    /* background: rgba(119, 20, 194, 0.10); */
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.service .rectangle-box .description .title-text {
    display: flex;
    height: 37.949px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #7714C2;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.25px;
}

.service .rectangle-box .description .title-desc {
    width: 645px;
    height: 197.736px;
    flex-shrink: 0;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 1px;
}