.nexolve .body {
    background: url("../../assets/images/Background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.nexolve .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}

.nexolve .heading::before,
.nexolve .heading::after {
    content: '';
    width: 209px;
    height: 2px;
    background-color: #7714C2;
    position: relative;
    display: inline;
    transform: translateY(-200%);
}

.nexolve .title {
    color: #303030;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2px;
}

.nexolve .card-section .only-card:not(:nth-child(n+3))::after {
    content: '';
    height: 0.1rem;
    display: inline-block;
    background: black;
    width: 100%;
    position: relative;
    left: 48%;
    top: -90px;
    z-index: -999;
}

.nexolve .card-section .only-card:not(:nth-child(-n+3))::after {
    content: '';
    height: 0.1rem;
    display: inline-block;
    background: black;
    width: 100%;
    position: relative;
    left: 48%;
    top: -90px;
    z-index: -999;
}

.nexolve .card-section .only-card:nth-child(3)::after,
.nexolve .card-section .only-card:last-child::before,
.nexolve .card-section .only-card:not(:nth-child(-n+3))::before {
    content: '';
    height: 0.1rem;
    display: inline-block;
    background: black;
    width: 70%;
    position: relative;
    rotate: 90deg;
    /* top: 10%; */
    z-index: -999;
    left: 0;
}

.nexolve .card-section .only-card:last-child::after,
.nexolve .card-section .only-card:nth-last-child(2)::after {
    content: none;
}



@media (min-width : 300px) and (max-width : 700px) {
    .nexolve .card-section .only-card:not(:nth-child(n+3))::after{
        width: 0%;
    }

    .nexolve .card-section .only-card:not(:nth-child(-n+3))::after{
        width: 0%;
    }
}