@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;700&family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;700&family=Open+Sans:wght@300&family=Poppins:wght@100;300;500&display=swap');


* {
  font-family: 'Inter', sans-serif;
}

body {
  font-family: 'Inter', sans-serif;
}